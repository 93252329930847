import { useSearchParams } from "react-router-dom";
import BackButton from "../../../components/close_button";
import Content from "../../../components/content";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import CanvaTitle from "./canva_title";
import ExpressTitle from "./express_title";
import Packages from "./packages";
import Terms from "./terms";
import Title from "./title";

function PackagePage() {
  const [searchParams] = useSearchParams();

  return (
    <>
      <style>
        {`
          @media screen and (max-width: 1023px) {
            #root {
              overflow: auto;
            }
          }
      `}
      </style>
      <div id="package_page">
        {searchParams.get("et") && <BackButton />}
        <Header />
        <Content>
          {searchParams.get("ct") && <CanvaTitle />}
          {searchParams.get("et") && <ExpressTitle />}
          {searchParams.get("lt") && <Title />}
          <Packages />
          <Terms />
        </Content>
        <Footer />
      </div>
    </>
  );
}

export default PackagePage;
