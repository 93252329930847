import { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import Footer from "./Footer";
import Hero from "./Hero";
import Navbar from "./Navbar";
import UserMilestone from "./UserMilestone";
import { initGA } from "./ga-utils";
import "./index.css";

function App() {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div className="App">
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        style={{ background: "white", color: "black" }}
        buttonStyle={{
          backgroundColor: "rgb(115, 48, 223)",
          color: "white",
          fontSize: "13px",
          borderRadius: "8px",
          padding: "16px",
        }}
        declineButtonStyle={{
          backgroundColor: "white",
          color: "gray",
          fontSize: "13px",
          borderRadius: "8px",
          padding: "16px",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Navbar />
      <Hero />
      <UserMilestone />
      <Footer />
    </div>
  );
}

export default App;
