function BackButton() {
  return null;
  return (
    <div style={{ margin: "0 0 0 auto", width: "100%" }}>
      <button
        onClick={() => window.history.back()}
        style={{
          width: "40px",
          height: "40px",
          margin: "15px",
          borderRadius: "99999px",
          fontSize: "40px", // Increase the font size
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        &larr;
      </button>
    </div>
  );
}

export default BackButton;
