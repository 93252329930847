import "./Hero.css";

function Hero() {
  return (
    <div className="hero-container">
      {/* Container */}
      <div className="hero-content">
        {/* Bild für mobile */}
        <div className="hero-image-mobile">
          <img
            src="./images/Effects-Split-p-500.jpg"
            alt="Video Preview"
            className="hero-image"
          />
        </div>

        {/* Text */}
        <div className="hero-text">
          <h1 className="hero-title">
            Full HD <br /> lip sync on
          </h1>
          <img
            src="./images/lip.svg"
            alt="Logo"
            className="hero-logo-desktop"
          />
          <img
            src="./images/lippurple.svg"
            alt="Logo"
            className="hero-logo-mobile"
          />

          <a
            href="https://www.lipsyncer.ai/?utm_source=lalamu.studio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="hero-button">Try for free</button>
          </a>
        </div>

        {/* Bild für Desktop */}
        <div className="hero-image-desktop">
          <img
            src="./images/Effects-Split-p-500.jpg"
            alt="Video Preview"
            className="hero-image"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
