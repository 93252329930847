import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../../api/endpoints";
import bgPaket from "../../../assets/images/BGpaket.svg";
import Loader from "../../../components/loader";
import { Payment, PaymentPackage } from "../../../redux/payment";
import { choosePackage, setPackages } from "../../../redux/reducers";
import Box from "./box";

function Packages() {
  const model: Payment = useSelector((state: any) => state.payment.value);
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const packages: PaymentPackage[] = await getPackages({
        bearerToken: model.apiToken,
      });
      packages.sort((a, b) => a.coins - b.coins);
      dispatch(setPackages(packages));
    };

    init();
  }, [model.apiToken, dispatch]);

  return (
    <div id="packages">
      {model.packages.length === 0 ? (
        <Loader />
      ) : (
        <>
          <Box
            key={0}
            credits="20 Free Credits"
            price="€ 0,00"
            benefits={[
              "20 video seconds",
              "No automatic\nsubscription renewal",
              "Refresh up to 20\nFree Credits after 4 weeks",
            ]}
          />
          <Box
            key={1}
            credits={model.packages[0].coins + " Credits"}
            price={PaymentPackage.mappingList(model.packages[0]).priceInEuro}
            backgroundSvg={bgPaket}
            benefits={[
              `from € ${(
                PaymentPackage.mappingList(model.packages[0]).priceInCent /
                100 /
                model.packages[0].coins
              ).toFixed(4)} per second `,
              `up to ${model.packages[0].coins / 60} video minutes`,
              "No automatic\nsubscription renewal",
              "High quality videos",
              "No watermark",
              "Valid for 4 weeks\nfrom purchase",
            ]}
            purchase={() => {
              dispatch(choosePackage(model.packages[0]));
            }}
          />
          <Box
            key={2}
            credits={model.packages[1].coins + " Credits"}
            price={PaymentPackage.mappingList(model.packages[1]).priceInEuro}
            backgroundSvg={bgPaket}
            badge="Most<br/>Popular"
            benefits={[
              `from € ${(
                PaymentPackage.mappingList(model.packages[1]).priceInCent /
                100 /
                model.packages[1].coins
              ).toFixed(4)} per second `,
              `up to ${model.packages[1].coins / 60} video minutes`,
              "No automatic\nsubscription renewal",
              "High quality videos",
              "No watermark",
              "Valid for 4 weeks\nfrom purchase",
            ]}
            purchase={() => {
              dispatch(choosePackage(model.packages[1]));
            }}
          />
          <Box
            key={3}
            credits={model.packages[2].coins + " Credits"}
            price={PaymentPackage.mappingList(model.packages[2]).priceInEuro}
            backgroundSvg={bgPaket}
            badge="<br/>10% OFF"
            benefits={[
              `from € ${(
                PaymentPackage.mappingList(model.packages[2]).priceInCent /
                100 /
                model.packages[2].coins
              ).toFixed(4)} per second `,
              `up to ${model.packages[2].coins / 60} video minutes`,
              "No automatic\nsubscription renewal",
              "High quality videos",
              "No watermark",
              "Valid for 4 weeks\nfrom purchase",
            ]}
            purchase={() => {
              dispatch(choosePackage(model.packages[2]));
            }}
          />
        </>
      )}
    </div>
  );
}

export default Packages;
