import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { postPaymentSession } from "../../../api/endpoints";
import Loader from "../../../components/loader";
import { Payment, PaymentPackage } from "../../../redux/payment";

const CheckoutForm: React.FC = () => {
  const model: Payment = useSelector((state: any) => state.payment.value);
  const [stripe, setStripe] = React.useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    const init = async () => {
      const stripe = await loadStripe(model.stripePublicKey);
      if (stripe && model.chosenPackage) {
        const stripeKey: { clientSecret: string } = await postPaymentSession({
          bearerToken: model.apiToken,
          creditId: PaymentPackage.mappingList(model.chosenPackage).creditId,
          quantity: 1,
          returnUrl: "https://lalamu.studio/payments/success.html",
        });
        setClientSecret(stripeKey.clientSecret);
        setStripe(stripe);
        setLoading(false);
      }
    };

    init();
  }, [model.stripePublicKey, model.chosenPackage, model.apiToken]);

  return (
    <>
      {loading ? (
        <div className="centered-loader">
          <Loader />
        </div>
      ) : (
        <EmbeddedCheckoutProvider stripe={stripe} options={{ clientSecret }}>
          <EmbeddedCheckout className="stripe-embed" />
        </EmbeddedCheckoutProvider>
      )}
    </>
  );
};

const StripePage: React.FC = () => {
  //const model: Payment = useSelector((state: any) => state.payment.value);

  return (
    <>
      <style>
        {`
          #app {
            background: #ffffff !important;
          }
          #root {
            overflow: auto !important;
          }
        `}
      </style>
      <CheckoutForm />
    </>
  );
};

export default StripePage;
