import React from "react";

function Title() {
  return (
    <div className="title">
      <p>Upgrade your credits</p>
    </div>
  );
}

export default Title;
