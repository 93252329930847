import { createSlice } from "@reduxjs/toolkit";
import { Payment } from "./payment";
import { putUser, putUserVerify } from "../api/endpoints";

export const paymentReducer = createSlice({
  name: "payment",
  initialState: { value: Payment.initial },
  reducers: {
    setEmail: (state, action) => {
      state.value = state.value.copyWith({ email: action.payload });
    },
    submitEmail: (state) => {
      state.value = state.value.copyWith({ emailSubmitted: true });
    },
    setOtp: (state, action) => {
      state.value = state.value.copyWith({ otp: action.payload });
    },
    checkOtp: (state) => {
      state.value = state.value.copyWith({ checkingOtp: true });
    },
    otpCorrect: (state, action) => {
      state.value = state.value.copyWith({
        otpCorrect: action.payload,
        checkingOtp: false,
      });
    },
    setPackages: (state, action) => {
      state.value = state.value.copyWith({ packages: action.payload });
    },
    setStripePublicKey: (state, action) => {
      state.value = state.value.copyWith({ stripePublicKey: action.payload });
    },
    setApiToken: (state, action) => {
      state.value = state.value.copyWith({ apiToken: action.payload });
    },
    choosePackage: (state, action) => {
      state.value = state.value.copyWith({ chosenPackage: action.payload });
    },
    userIsVerified: (state) => {
      state.value = state.value.copyWith({
        emailSubmitted: true,
        otpCorrect: true,
      });
    },
  },
});

export const {
  setEmail,
  submitEmail,
  setOtp,
  checkOtp,
  setApiToken,
  otpCorrect,
  setPackages,
  setStripePublicKey,
  choosePackage,
  userIsVerified,
} = paymentReducer.actions;

export default paymentReducer.reducer;
