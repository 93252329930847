import "./Navbar.css";

function Navbar() {
  return (
    <nav>
      <div className="navbar">
        <img src="./images/lalamu.svg" alt="Logo" />
      </div>
    </nav>
  );
}

export default Navbar;
