import React from "react";

function Title() {
  return (
    <div className="title">
      <p>
        We sent you an Email<br></br>Please enter the code
      </p>
    </div>
  );
}

export default Title;
