import { useSearchParams } from "react-router-dom";
import BackButton from "../../../components/close_button";
import Content from "../../../components/content";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Forms from "./forms";
import Terms from "./terms";
import Title from "./title";

function OTPPage() {
  const [searchParams] = useSearchParams();

  return (
    <div>
      <Header />
      <Content>
        {searchParams.get("et") && <BackButton />}
        <Title />
        <Forms />
        <Terms />
      </Content>
      <Footer />
    </div>
  );
}

export default OTPPage;
