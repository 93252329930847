"use client";

import "./UserMilestone.css";

const UserMilestone = () => {
  return (
    <div className="user-milestone">
      <div className="heading">After 4 months, we reached</div>
      <span className="milestone-number">400.000</span>
      <div className="subheading">happy users on</div>
      <a href="https://www.canva.com/your-apps/AAF5prtvncA/lalamu-studio">
        <img src="/images/Canva-Logo.svg" alt="Canva Logo" className="logo" />
      </a>
    </div>
  );
};

export default UserMilestone;
