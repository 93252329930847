import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getStripePublicKey, getUser, postUser } from "./api/endpoints";
import Loader from "./components/loader";
import EmailPage from "./pages/payments/email_page/email_page";
import OTPPage from "./pages/payments/otp_page/otp_page";
import PackagePage from "./pages/payments/packages_page/package_page";
import StripePage from "./pages/payments/stripe_page/stripe_page";
import { CurrentPage, Payment } from "./redux/payment";
import {
  setApiToken,
  setStripePublicKey,
  userIsVerified,
} from "./redux/reducers";

import "./css/desktop.css";
import "./css/index.css";
import "./css/mobile.css";
import "./css/tablet.css";

function Payments() {
  const model: Payment = useSelector((state: any) => state.payment.value);
  const dispatch = useDispatch();
  // Get the search parameters from the URL
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const backgroundTasks = async () => {
      try {
        const apiToken: { accessToken: string } = await postUser(searchParams);
        const user: { isMailVerified: boolean } = await getUser(
          apiToken.accessToken
        );
        if (user.isMailVerified) {
          const stripeKey: { publicKey: string } = await getStripePublicKey({
            bearerToken: apiToken.accessToken,
          });
          dispatch(setStripePublicKey(stripeKey.publicKey));
          dispatch(setApiToken(apiToken.accessToken));
          dispatch(userIsVerified());
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setError("An error occured. Please try again later.");
      }
    };

    backgroundTasks();
  }, [searchParams, dispatch]);

  return (
    <div id="app" className="payments">
      {error ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {error}
        </div>
      ) : (
        <>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                id="content"
                style={{ justifyContent: "center", margin: "auto" }}
              >
                <Loader />
              </div>
            </div>
          ) : model.page === CurrentPage.Stripe ? (
            <StripePage />
          ) : model.page === CurrentPage.Otp ? (
            <OTPPage />
          ) : model.page === CurrentPage.Packages ? (
            <PackagePage />
          ) : (
            <EmailPage />
          )}
        </>
      )}
    </div>
  );
}

export default Payments;
