function Terms() {
  return (
    <div className="terms terms-packages">
      <p>
        By purchasing credits, I agree to Lalamu Studio's{" "}
        <a
          href="https://lalamu.studio/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          terms & conditions
        </a>{" "}
        and{" "}
        <a
          href="https://lalamu.studio/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>
        .
      </p>
    </div>
  );
}

export default Terms;
