function ExpressTitle() {
  return (
    <div className="title" style={{ maxWidth: "100%" }}>
      <span
        className="canva-title"
        style={{
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        Add lipsyncer credits on Adobe Express
      </span>
    </div>
  );
}

export default ExpressTitle;
