function CanvaTitle() {
  return (
    <div className="title" style={{ maxWidth: "100%" }}>
      <span
        className="canva-title"
        style={{
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        Add lipsyncer credits on
        <img
          src="/images/canva-logo-2024.png"
          alt="Canva Logo"
          className="logo"
          style={{ width: "120px", marginLeft: "15px" }}
        />
      </span>
    </div>
  );
}

export default CanvaTitle;
