import { baseURL, x_api_key } from "./api";

const getTokenPlatformAndEmail = (searchParams: any) => {
  let token = "";
  let platform = "";
  let email = "";
  const canvaToken = searchParams.get("ct");
  if (canvaToken) {
    token = canvaToken;
    platform = "canva";
  }
  const expressToken = searchParams.get("et");
  if (expressToken) {
    token = expressToken;
    platform = "express";
  }
  const lipsyncerToken = searchParams.get("lt");
  if (lipsyncerToken) {
    token = lipsyncerToken;
    platform = "lipsyncer";
    email = searchParams.get("email");
  }

  return { token, platform, email };
};

// Sends external auth token to API and gets a new lalamu token back
export const postUser = async (searchParams: any) => {
  const { token, platform, email } = getTokenPlatformAndEmail(searchParams);
  const response = await fetch(`${baseURL}user`, {
    method: "POST",
    headers: {
      "Content-Length": JSON.stringify({
        token,
        platform,
      }).length.toString(),
      "x-api-key": x_api_key,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, platform, email }),
  });
  if (response.status < 200 || response.status >= 300) {
    //TODO: Implement error states
  }

  const data = await response.json();
  return data;
};

export const putUser = async ({
  bearerToken,
  email,
}: {
  bearerToken: string;
  email: string;
}) => {
  const response = await fetch(`${baseURL}user`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Length": JSON.stringify({ email: email }).length.toString(),
      "Content-Type": "application/json",
      "x-api-key": x_api_key,
    },
    body: JSON.stringify({ email: email }),
  });
  if (response.status < 200 || response.status >= 300) {
    return false;
  }
  return true;
};

export const putUserVerify = async ({
  bearerToken,
  verificationCode,
}: {
  bearerToken: string;
  verificationCode: string;
}) => {
  const response = await fetch(`${baseURL}user/verify`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Length": JSON.stringify({
        verificationCode: verificationCode,
      }).length.toString(),
      "Content-Type": "application/json",
      "x-api-key": x_api_key,
    },
    body: JSON.stringify({ verificationCode: verificationCode }),
  });
  if (response.status < 200 || response.status >= 300) {
    return false;
  }
  return true;
};

export const getUser = async (bearerToken: string) => {
  const response = await fetch(`${baseURL}user`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "x-api-key": x_api_key,
    },
  });
  if (response.status < 200 || response.status >= 300) {
    //TODO: handle error
  }

  const data = await response.json();
  return data;
};

export const getPackages = async ({ bearerToken }: { bearerToken: string }) => {
  const response = await fetch(`${baseURL}credit`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "x-api-key": x_api_key,
    },
  });
  if (response.status < 200 || response.status >= 300) {
    //TODO: handle error
  }

  const data = await response.json();
  return data;
};

export const getStripePublicKey = async ({
  bearerToken,
}: {
  bearerToken: string;
}) => {
  const response = await fetch(`${baseURL}payment`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "x-api-key": x_api_key,
    },
  });
  if (response.status < 200 || response.status >= 300) {
    //TODO: handle error
  }

  const data = await response.json();
  return data;
};

/// NO LONGER USED - USE POST PAYMENT SESSION INSTEAD
/// amount should be an integer, in cent
/// i.e. { "creditId" : 1,  "currency" : "eur", "amount" : 599 }
export const postPaymentIntent = async ({
  bearerToken,
  creditId,
  currency = "eur",
  amount,
}: {
  bearerToken: string;
  creditId: number;
  currency?: string;
  amount: number;
}) => {
  const response = await fetch(`${baseURL}payment/intent`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Length": JSON.stringify({
        creditId: creditId,
        currency: currency,
        amount: amount,
      }).length.toString(),
      "Content-Type": "application/json",
      "x-api-key": x_api_key,
    },
    body: JSON.stringify({
      creditId: creditId,
      currency: currency,
      amount: amount,
    }),
  });
  if (response.status < 200 || response.status >= 300) {
    //TODO: Implement error states
  }

  const data = await response.json();
  return data;
};

/// returns:
/// - success: bool
/// - id: string
/// - clientSecret: string
/// - errorMessage: string | null
export const postPaymentSession = async ({
  bearerToken,
  creditId,
  quantity,
  returnUrl,
}: {
  bearerToken: string;
  creditId: number;
  quantity: number;
  returnUrl: string;
}) => {
  const json: string = JSON.stringify({
    creditId: creditId,
    quantity: quantity,
    returnUrl: returnUrl,
  });
  const response = await fetch(`${baseURL}payment/session`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Length": json.length.toString(),
      "Content-Type": "application/json",
      "x-api-key": x_api_key,
    },
    body: json,
  });
  if (response.status < 200 || response.status >= 300) {
    // TODO: implement error states
  }

  const data = await response.json();
  return data;
};
