import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postUser, putUser } from "../../../api/endpoints";
import Loader from "../../../components/loader";
import { Payment } from "../../../redux/payment";
import { setApiToken, setEmail, submitEmail } from "../../../redux/reducers";

function Forms() {
  const dispatch = useDispatch();
  const payment: Payment = useSelector((state: any) => state.payment.value);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  // Get the search parameters from the URL
  const [searchParams] = useSearchParams();

  const sendEmail = async () => {
    setLoading(true);

    const apiToken: { accessToken: string } = await postUser(searchParams);
    if (!apiToken) {
      return;
    }
    dispatch(setApiToken(apiToken.accessToken));
    if (payment.isSanitizedEmail()) {
      const emailPut = await putUser({
        bearerToken: apiToken.accessToken,
        email: payment.email,
      });
      if (emailPut) {
        dispatch(submitEmail());
      }
    }
    setLoading(false);
  };

  return (
    <div id="forms">
      <form>
        <input
          type="text"
          id="email"
          name="email"
          required={true}
          placeholder="Type your Email address"
          onChange={(e) => {
            dispatch(setEmail(e.target.value));
          }}
        />
        {loading ? (
          <Loader />
        ) : (
          <button
            type="submit"
            onClick={(event) => {
              // needed because input adds the value as a query parameter to the URL
              event.preventDefault();
              sendEmail();
            }}
            onSubmit={(event) => {
              // needed because input adds the value as a query parameter to the URL
              event.preventDefault();
              sendEmail();
            }}
          >
            Continue
          </button>
        )}
      </form>
    </div>
  );
}

export default Forms;
