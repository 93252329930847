import React from "react";

function Terms() {
  return (
    <div className="terms">
      <p>
        By signing in, I confirm that I have read and accepted Lalamu Studio's{" "}
        <a href="https://lalamu.studio/terms-and-conditions/" target="_blank">
          terms & conditions
        </a>{" "}
        and{" "}
        <a href="https://lalamu.studio/privacy-policy/" target="_blank">
          privacy policy
        </a>
        .
      </p>
    </div>
  );
}

export default Terms;
