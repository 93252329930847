import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getPackages,
  getStripePublicKey,
  putUserVerify,
} from "../../../api/endpoints";
import Loader from "../../../components/loader";
import { Payment } from "../../../redux/payment";
import {
  checkOtp,
  otpCorrect,
  setOtp,
  setPackages,
  setStripePublicKey,
} from "../../../redux/reducers";

function Forms() {
  const model: Payment = useSelector((state: any) => state.payment.value);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const submit = async () => {
    dispatch(checkOtp());
    const verified = await putUserVerify({
      bearerToken: model.apiToken,
      verificationCode: model.otp,
    });
    const packages = await getPackages({
      bearerToken: model.apiToken,
    });
    const stripeKey: { publicKey: string } = await getStripePublicKey({
      bearerToken: model.apiToken,
    });
    dispatch(setStripePublicKey(stripeKey.publicKey));
    dispatch(setPackages(packages));
    dispatch(otpCorrect(verified));
  };

  return (
    <div id="forms">
      <form>
        <OtpInput
          value={model.otp}
          onChange={(otp) => dispatch(setOtp(otp))}
          numInputs={6}
          renderSeparator={<span className="purple"> / </span>}
          renderInput={(props) => <input {...props} />}
        />
        {model.checkingOtp ? (
          <Loader />
        ) : (
          <button type="submit" onClick={submit} onSubmit={submit}>
            Continue
          </button>
        )}
      </form>
    </div>
  );
}

export default Forms;
