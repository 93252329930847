import Logo from "./lipsyncer.ai-logo";

function Header() {
  return (
    <div id="header" style={{ padding: "50px" }}>
      <span id="black-logo">
        <Logo />
      </span>
      <span id="white-logo">
        <Logo fill="white" />
      </span>
    </div>
  );
}

export default Header;
