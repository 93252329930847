import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* Container Mobile */}
      <div className="footer-mobile-container">
        {/* Menu-Links-Wrapper */}
        <div className="footer-menu-links-wrapper">
          <p>
            <a href="/terms-of-use">Terms of Use</a>
          </p>
          <p>
            <a href="/privacy-policy">Privacy Policy</a>
          </p>
          <p>
            <a href="/imprint">Impressum</a>
          </p>
        </div>
        {/* Logo-Copyright-Text-Wrapper */}
        <div className="footer-logo-copyright-wrapper">
          <img
            src="./images/lalamu.svg"
            alt="Lalamu Studios"
            className="footer-logo"
          />
          <p className="footer-copyright">© Lalamu Entertainment GmbH</p>
        </div>
      </div>

      {/* Container Desktop */}
      <div className="footer-desktop-container">
        <div className="footer-desktop-content">
          <img
            src="./images/lalamu.svg"
            alt="Lalamu Studios"
            className="footer-desktop-logo"
          />
          <div className="footer-desktop-info">
            <p className="footer-desktop-copyright">
              © Lalamu Entertainment GmbH
            </p>
            <div className="footer-desktop-links">
              <p>
                <a href="/terms-and-conditions">Terms and Conditions</a>
              </p>
              <p>
                <a href="/privacy-policy">Privacy Policy</a>
              </p>
              <p>
                <a href="/imprint">Impressum</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
