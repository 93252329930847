export class PaymentPackage {
  stripeId: string;
  name: string;
  coins: number;

  // depending on coins amount
  static mappingList(pkg: PaymentPackage) {
    if (pkg.coins === 300) {
      return {
        priceInCent: 490,
        priceInEuro: "€ 4,90",
        creditId: 2,
      };
    } else if (pkg.coins === 600) {
      return {
        priceInCent: 890,
        priceInEuro: "€ 8,90",
        creditId: 1,
      };
    } else if (pkg.coins === 3000) {
      return {
        priceInCent: 3990,
        priceInEuro: "€ 39,90",
        creditId: 20,
      };
    }
    return { priceInCent: 10000, priceInEuro: "€ 100,00", creditId: 999 };
  }

  constructor({
    stripeId,
    name,
    coins,
  }: {
    stripeId: string;
    name: string;
    coins: number;
  }) {
    this.stripeId = stripeId;
    this.name = name;
    this.coins = coins;
  }
}

export class Payment {
  apiToken: string;
  email: string;
  emailSubmitted: boolean;
  otp: string;
  checkingOtp: boolean;
  otpCorrect?: boolean;
  packages: PaymentPackage[];
  stripePublicKey: string;
  chosenPackage?: PaymentPackage;

  constructor({
    apiToken,
    email,
    emailSubmitted,
    otp,
    checkingOtp,
    otpCorrect,
    packages,
    stripePublicKey,
    chosenPackage,
  }: {
    apiToken: string;
    email: string;
    emailSubmitted: boolean;
    otp: string;
    checkingOtp: boolean;
    otpCorrect?: boolean;
    packages: PaymentPackage[];
    stripePublicKey: string;
    chosenPackage?: PaymentPackage;
  }) {
    this.apiToken = apiToken;
    this.email = email;
    this.emailSubmitted = emailSubmitted;
    this.otp = otp;
    this.checkingOtp = checkingOtp;
    this.otpCorrect = otpCorrect;
    this.packages = packages;
    this.stripePublicKey = stripePublicKey;
    this.chosenPackage = chosenPackage;
  }

  static initial = new Payment({
    apiToken: "",
    email: "",
    emailSubmitted: false,
    otp: "",
    checkingOtp: false,
    packages: [],
    stripePublicKey: "",
  });

  copyWith({
    apiToken,
    email,
    emailSubmitted,
    otp,
    checkingOtp,
    otpCorrect,
    packages,
    stripePublicKey,
    chosenPackage,
  }: {
    apiToken?: string;
    email?: string;
    emailSubmitted?: boolean;
    otp?: string;
    checkingOtp?: boolean;
    otpCorrect?: boolean;
    packages?: PaymentPackage[];
    stripePublicKey?: string;
    chosenPackage?: PaymentPackage;
  }) {
    return new Payment({
      apiToken: apiToken ?? this.apiToken,
      email: email ?? this.email,
      emailSubmitted: emailSubmitted ?? this.emailSubmitted,
      otp: otp ?? this.otp,
      checkingOtp: checkingOtp ?? this.checkingOtp,
      otpCorrect: otpCorrect ?? this.otpCorrect,
      packages: packages ?? this.packages,
      stripePublicKey: stripePublicKey ?? this.stripePublicKey,
      chosenPackage: chosenPackage ?? this.chosenPackage,
    });
  }

  get page(): CurrentPage {
    if (this.emailSubmitted && this.otpCorrect && this.chosenPackage) {
      return CurrentPage.Stripe;
    } else if (this.emailSubmitted && this.otpCorrect) {
      return CurrentPage.Packages;
    } else if (this.emailSubmitted) {
      return CurrentPage.Otp;
    } else {
      return CurrentPage.Email;
    }
  }

  isSanitizedEmail() {
    // make sure, it follows a basic email format and does not include malicious code, using regex
    const regex = /\S+@\S+\.\S+/;
    return regex.test(this.email);
  }
}

export enum CurrentPage {
  Email,
  Otp,
  Packages,
  Stripe,
}
