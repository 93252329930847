import check from "../../../assets/images/check.svg";

function Box({
  credits,
  price,
  benefits,
  purchase,
  backgroundSvg,
  badge,
}: {
  credits: string;
  price: string;
  benefits: string[];
  purchase?: Function;
  backgroundSvg?: string;
  badge?: string;
}) {
  const classes =
    "package-box " +
    (!purchase ? "no-buy " : "") +
    (backgroundSvg ? "special" : "");

  const customStyle = backgroundSvg
    ? { backgroundImage: "url(" + backgroundSvg + ")" }
    : {};

  return (
    <div className={classes} style={customStyle}>
      <style>
        {`
        .package-box {
          position: relative;
          overflow: hidden; /* Ensure the badge is cut off by the box */
        }

        .badge {
          position: absolute;
          top: -35px; /* Adjust as needed */
          right: -65px; /* Adjust as needed */
          background: linear-gradient(to right, #ffeb3b, #fbc02d);
          color: black !important;
          padding-left: 40px;
          padding-right: 30px;
          transform: rotate(35deg);
          font-weight: bold;
          font-size: 14px;
          line-height: 1;
          z-index: 1;
          width: 105px; /* Adjust as needed */
          
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding-top: 50px;
          padding-bottom: 10px;
        }`}
      </style>
      {badge && (
        <div className="badge" dangerouslySetInnerHTML={{ __html: badge }} />
      )}
      <div className="package-credits">{credits}</div>
      <div className="package-price">{price}</div>

      <div className="package-benefits">
        {benefits.map((benefit) => (
          <div className="package-benefit" key={benefit}>
            <img width={15} src={check} alt="Checkmark" />
            {benefit}
          </div>
        ))}
      </div>
      {purchase && (
        <button className="package-purchase" onClick={() => purchase()}>
          Order now
        </button>
      )}
    </div>
  );
}

export default Box;
